import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';


// import p1 from 'assests/Photos/Clubs/LitreryClub/2024/Literary/1.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../Literaryclub2023/components/Sidebar2024';
import Archive2023 from '../Literaryclub2023/components/Archive2023';
import LiterarySidebar from '../Literaryclub2023/components/LiterarySidebar';

const LiteraryAssociation2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Literary/1.JPG`;


    const photos = [
        {
            src: p1,
            source: p1,
            rows: 2,
            cols: 2,
        },
      
        // {
        //     src: p5,
        //     source: p5,
        //     rows: 1,
        //     cols: 1,
        // }, 
        // {
        //     src: p6,
        //     source: p6,
        //     rows: 1.4,
        //     cols: 2,
        // }
    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                Pick and Speak
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 6 to 8  &  Date: 2 & 9 August 2024
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                Literary Association (Junior) organised the "Pick and Speak" activity to foster public speaking and literary skills. It was an individual activity conducted for the students of Classes 6 – 8. The Preliminary Round was conducted on Friday, 02 August 2024 and 104 students participated, out of which the 4 best speakers were selected per house. 16 finalists enthusiastically participated in the Final Round on Friday, 09 August 2024. They selected random literary topics or themes and spoke on them spontaneously, promoting eloquence. Three best speakers secured first, second and third positions respectively. The winners demonstrated improved fluency, confidence in their speaking abilities and enhanced ability to think critically and articulate complex ideas succinctly. 
                                <br></br>
                                The "Pick and Speak" activity has been successful in achieving its objectives. It has proven to be an effective tool for enhancing public speaking skills and encouraging deeper engagement with literary topics. The activity fostered a collaborative and supportive environment, with the judges actively participating and providing constructive feedback. 
                                <br></br>
                                
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "Words have incredible power. They can make people’s hearts soar to greater heights!"

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        
                        <Box marginBottom={4}>
                            <Sidebar2024/>
                        </Box>
                        {/* <Box marginBottom={4}>
                                    <Archive2023/>
                        </Box> */}
                        <Box marginBottom={4}>
                                    <LiterarySidebar/>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default LiteraryAssociation2024;